<template>
  <div
    @click="areOptionsVisible = !areOptionsVisible"
    class="languages"
    :class="areOptionsVisible ? 'show' : ''"
  >
    <BaseIcon name="langIco" verAlign />
    <p class="languages__current">
      {{ selectedUpperCase }}
      <span></span>
    </p>
    <!-- @click.prevent="switchLocale('en') -->
    <ul v-if="areOptionsVisible" class="options list-reset">
      <li
        @click="selectOption(option)"
        v-for="option in options"
        :key="option.value"
        :class="option.active ? 'options__item active' : 'options__item'"
      >
        {{ option.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import BaseIcon from '@/components/UI/BaseIcon.vue'
export default {
  components: {
    BaseIcon,
  },

  data() {
    return {
      areOptionsVisible: false,
    }
  },
  props: {
    options: {
      type: Array,
      default() {
        return []
      },
    },
    selected: {
      type: String,
      default: '',
    },
  },
  methods: {
    switchLocale(locale) {
      if (this.$i18n.locale !== locale) {
        localStorage.setItem('lang', locale)
        this.$i18n.locale = locale
        this.$moment.locale(locale)
      }
    },
    selectOption(option) {
      this.options.forEach((opt) => (opt.active = false))
      option.active = !option.active
      this.$emit('select', option)
      this.switchLocale(option.name)
    },
  },
  computed: {
    selectedUpperCase() {
      return `${this.selected[0].toUpperCase()}${this.selected[1]}`
    },
  },

  mounted() {
    window.addEventListener('click', (event) => {
      if (!this.$el.contains(event.target)) {
        this.areOptionsVisible = false
      }
    })
  },
  
}
</script>

<style lang="scss">
.languages {
  display: flex;
  border-radius: 10px;
  background-color: var(--color-black-800);
  color: var(--color-black-500);
  max-width: 71px;
  width: 100%;
  padding: 4px;
  margin-right: 8px;
  min-width: 72px;
  cursor: pointer;
  position: relative;
  transition: border-radius 0.25s ease-in-out;
  li,
  p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  &__current {
    display: flex;
    align-items: center;
    padding: 0 3px;
    span {
      display: inline-block;
      transition: transform 0.25s ease-in-out;
      margin-left: 3px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 4.5px 0 4.5px;
      border-color: var(--color-white-50) transparent transparent transparent;
    }
  }
  &.show {
    border-radius: 10px 10px 0 0;
    .languages__current span {
      transform: rotate(-180deg);
    }
  }
  .options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: var(--color-black-800);
    border-radius: 0 0 20px 20px;
    z-index: 10;
    &__item {
      padding: 5px 26px;
      transition: color 0.25s ease-in-out, background-color 0.25s ease-in-out;
      &:hover,
      &.active {
        background-color: var(--color-white-10);
        color: var(--color-black-100);
      }
      &:last-child {
        border-radius: 0 0 10px 10px;
      }
      &.active {
        background-color: var(--color-white-10);
        color: var(--color-black-100);
      }
    }
  }

  &--light {
    background-color: var(--color-charade);
    .options {
      &__item {
        background-color: var(--color-charade);
      }
    }
  }
}
</style>
