import {
    getTopicsService,
    createTicketService,
    getTicketListService,
    getFaqService,
    getTicketService,
    getTicketMessagesService,
    createMessageService,
    closeTicketService,
} from "@/api/ticket"
import { vm } from "@/main.js";

export default {
    state: {
        topics: [],
        tickets: [],
        faq: [],
        ticket: {},
        messages: [],
        messagesMeta: {},
    },
    getters: {
        getTopics(state) {
            return state.topics.map((x) => ({
                id: x.id,
                label: x.title,
            }))
        },
        tickets(state) {
            return state.tickets.map((x) => {
                return {
                    ...x,
                    date: vm.$moment(x.created_at).format("DD.MM.YYYY"),
                }
            })
        },
        faq(state) {
            return state.faq
        },
        messages(state) {
            return state.messages
        },
        messagesMeta(state) {
            return state.messagesMeta
        },
    },
    mutations: {
        setTopics(state, arr) {
            state.topics = arr
        },
        setTickets(state, tickets) {
            state.tickets = tickets
        },
        setTicket(state, ticket) {
            state.ticket = {
                ...state.ticket,
                ...ticket,
            }
        },
        setFaq(state, faq) {
            state.faq = faq
        },
        setMessages(state, messages) {
            state.messages = messages
        },
        setMessagesMeta(state, meta) {
            state.messagesMeta = {
                ...meta,
            }
        },
        addMessages(state, message) {
            let newMessages = message
            if (!Array.isArray(message)) {
                newMessages = [message]
            }

            state.messages = [...state.messages, ...newMessages]
        },
    },
    actions: {
        async getTopics({ commit, dispatch }, request) {
            const [err, result] = await getTopicsService(request)

            if (err) throw err

            commit("setTopics", result)

            return result
        },
        async createTicket({ commit }, request) {
            const [err, result] = await createTicketService(request)

            if (err) throw err

            return result
        },
        async getTickets({ commit }, request) {
            const [err, result] = await getTicketListService(request)

            if (err) throw err

            commit("setTickets", result.data)

            return result
        },
        async getFaq({ commit }, request) {
            const [err, result] = await getFaqService(request)

            if (err) throw err

            commit("setFaq", result)

            return result
        },
        async getTicket({ commit, dispatch }, request) {
            const [err, ticket] = await getTicketService(request)

            if (err) throw err

            commit("setTicket", ticket)

            const messages = dispatch("getMessages", request)

            return {
                ...ticket,
                messages: messages ? messages.data : [],
            }
        },
        async getMessages({ commit }, request) {
            const [err, messages] = await getTicketMessagesService({
                ...request,
                count: 20,
            })

            if (err) throw err

            commit(request.page ? "addMessages" : "setMessages", messages.data)
            commit("setMessagesMeta", messages.meta)

            return messages
        },
        async sendChatMessage({ commit }, request) {
            const [err, result] = await createMessageService(request)

            if (err) throw err

            commit("addMessages", result)

            return result
        },
        async closeTicket({ commit }, request) {
            const [err, result] = await closeTicketService(request)

            if (err) throw err

            commit("setTicket", { status: result.status })

            return result
        },
    },
    namespaced: true,
}
