<template>
    <img :src="avatarPath" :alt="user.name" @error="defaultAvatarCallback" />
</template>

<script>
import { createImageHref } from "@/helpers/images";

export default {
    props: {
        user: Object,
    },
    computed: {
        avatarPath() {
            return createImageHref(this.user && this.user.avatar)
        },
    },
    methods: {
        defaultAvatarCallback(e) {
            e.target.src = "/assets/avatar.jpg"
        },
    },
}
</script>
