<template>
    <div class="sidebar" :class="this.mini ? 'hide' : ''">
        <div class="sidebar-inner">
            <div class="sidebar__body">
                <div class="sidebar-inner__top">
                    <router-link to="/" class="sidebar__logo logo d-flex align-center">
                        <!-- <img class="logo-open" src="/assets/account/logo.svg" alt="logo" /> -->
                        <BaseIcon name="mainLogo" verAlign/>
                    </router-link>
                    
                    <div class="center">
                        <BaseIcon name="burgerMenu" class="sidebar-burger" @event="openMenu" v-show="isActiveWallet"/>
                    </div>
                    <BaseIcon name="logout" class="sign-out__btn" @event="exit" v-if="isActiveWallet"/>

                </div>
                <!-- <div class="sidebar-languages">
                    <Language class="languages--light" :selected="selected" @select="optionSelect" :options="options" />
                </div> -->
                <Menu :func="openMenu" />
            </div>
            <div class="sidebar-inner__buttons">
                <SidebarBottom @logout="exit"/>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex"
import Menu from "./Menu.vue"
import Language from "../UI/Language.vue"
import BaseIcon from '@/components/UI/BaseIcon.vue'
import SidebarBottom from '../Dashboard/SidebarBottom.vue'
export default {
    data() {
        return {
            mini: false,
            isOpen: true,
            windowWidth: window.innerWidth,
            options: [
                { name: "ru", value: 1 },
                { name: "en", value: 2 },
                { name: "cn", value: 3 }
            ],
            selected: "ru"
        }
    },
    components: {
        Menu,
        Language,
        BaseIcon,
        SidebarBottom,
    },
    computed: {
        ...mapGetters("crypto", ["isActiveWallet", "getToken"])
    },
    methods: {
        optionSelect(option) {
            this.selected = option.name
        },
        addClass() {
            this.mini = !this.mini

            document
                .querySelectorAll(".nav-list__link")
                .forEach((link) => link.classList.toggle("nav-list__link--hide"))
        },
        addClassOnResize() {
            this.mini = true
        },
        ...mapActions("crypto", ["logOut"]),
        ...mapMutations("user", ["clearUser"]),

        exit() {
            this.logOut()
            this.clearUser()
        },

        openMenu() {
            const body = document.body

            this.mini = !this.mini
            body.classList.contains("hidden") ? body.classList.remove("hidden") : body.classList.add("hidden")
        },
        openSearch() {
            this.setSearchModal({ visible: true, type: "modal" })
        },
        ...mapMutations("ui", ["setSearchModal"])
    },

    mounted() {
            window.addEventListener('click', (event) => {
            if (!this.$el.contains(event.target)) {
                this.mini = false
            }
        })
    },
}
</script>

<style lang="scss">
.sign-out__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    width: var(--indent-32);
    height: var(--indent-32);
    background: #292929;
    border-radius: var(--indent-8);
    margin-left: var(--indent-32);
    cursor: pointer;
         @media(max-width: 991px) {
            display: none;
         }
}

.sidebar-burger {
    width: 34px;
    height: 34px;
    color: var(--color-white-50);
    stroke: var(--color-white-50);
}
.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

</style>