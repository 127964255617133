export default {
    state: {
        isShow: false,
        processingModal: {
            isShown: false,
            text: ""
        }
    },
    getters: {
        processingModal(state) {
            return state.processingModal
        }
    },
    mutations: {
        showPreloader: (state, isShow) => {
            state.isShow = isShow
        },
        showProcessingModal: (state, { isShown, text }) => {
            state.processingModal.isShown = isShown
            state.processingModal.text = text !== undefined ? text : ""
        }
    },
    namespaced: true
}
