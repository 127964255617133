<template>
  <div class="breadcrumbs">{{ currentPage.name }}</div>
</template>

<script>
export default {
  data() {
    return {
      breadCrumbs: [
        {
          name: 'Home',
          routeName: 'dashboard',
        },
        {
          name: 'Force Systems',
          routeName: 'classic',
        },
        {
          name: 'My Team',
          routeName: 'systems_referral',
        },
        {
          name: 'Academy',
          pageName: 'academy',
        },
        {
          name: 'Settings',
          routeName: 'settings',
        },
        {
          name: 'Help',
          routeName: 'help',
        },
      ],
    }
  },

  computed: {
    currentPage() {
      return this.breadCrumbs.find((el) => el.routeName === this.$route.name)
    },
  },
}
</script>

<style lang="scss">
.breadcrumbs {
  font-size: 22px;
  font-weight: 500;
  line-height: 32px;
  color: #666666;
}
</style>