<template>
    <div class="uploader" :class="[{ 'has-error': error }]">
        <div class="uploader__wrapper">
            <input :id="_uid" ref="uploadInput" type="file" v-bind="$attrs" @change="handleUpload" />

            <slot :trigger="triggerUploadWindow" :error="uploadError" name="preview">
                <div class="uploader__trigger" @click="triggerUploadWindow">
                    <svg-vue icon="paperclip" />
                    <span>{{ $t("ui.uploader.attach") }}</span>
                </div>
            </slot>

            <slot :file="file" name="info">
                <div class="uploader__files" v-if="includeReader">
                    <div v-for="(f, idx) in fileList" :key="idx" class="uploader__file-image">
                        <img :src="readers[idx]" />
                        <div class="uploader__file-remove" @click="removeFile(idx)">
                            <svg-vue icon="trash" />
                        </div>
                    </div>
                </div>
                <div class="uploader__files uploader__files--info">
                    <div v-for="(f, idx) in fileList" :key="idx" class="uploader__file-info">
                        <span class="uploader__file-name">{{ f.name }}</span>
                        <div class="uploader__file-delete" @click="removeFile(idx)">
                            <svg-vue icon="close" />
                        </div>
                    </div>
                </div>
            </slot>

            <slot :error="uploadError" name="error">
                <div v-if="uploadError" class="uploader__error">
                    <span class="uploader__error-name">{{ uploadError }}</span>
                </div>
            </slot>
        </div>
    </div>
</template>

<script>
import { bytesToMegaBytes } from "@/helpers/bytes"

export default {
    name: "Uploader",
    components: {},
    props: {
        file: {
            type: [Object, Array],
            required: false,
        },
        allowedMime: {
            type: Array,
            required: false,
        },
        maxSize: {
            type: Number,
            required: false,
        },
        includeReader: {
            type: Boolean,
            required: false,
        },
        error: {
            type: [String, Boolean],
            required: false,
        },
    },
    data() {
        return {
            uploadError: undefined,
            readers: [],
        }
    },

    watch: {
        uploadError(error) {
            this.$emit("handleError", error)
        },
    },
    computed: {
        fileList() {
            if (Array.isArray(this.file)) {
                return this.file
            } else {
                return [this.file]
            }
        },
    },
    methods: {
        setValue(file) {
            this.$emit("onChange", file)
        },
        setFileReaderValue(res) {
            this.readers.push(res)
            this.$emit("onReader", res)
        },
        triggerUploadWindow() {
            this.$refs.uploadInput.click()
        },
        removeFile(idx) {
            const newFiles = this.file.filter((x, i) => i !== idx)
            const newReaders = this.readers.filter((x, i) => i !== idx)
            this.setValue(newFiles)
            this.readers = newReaders
        },
        handleUpload(e) {
            const files = e.target.files
            // cleanup
            this.uploadError = undefined
            this.setValue(undefined)
            this.readers = []

            let resFile = []

            ;[...files].forEach((file) => {
                // limit mime
                if (this.allowedMime) {
                    const mimeType = file.type ? file.type.split("/")[1] : undefined

                    if (!this.allowedMime.includes(mimeType)) {
                        this.uploadError = this.$t("ui.uploader.badMime")
                        return false
                    }
                }

                // limit size
                if (this.maxSize) {
                    const sizeInMb = bytesToMegaBytes(file.size)

                    if (sizeInMb > this.maxSize) {
                        this.uploadError = this.$t("ui.uploader.maxSize", { size: this.maxSize })
                        return false
                    }
                }

                resFile.push(file)

                if (this.includeReader) {
                    const reader = new FileReader()
                    reader.onload = (ev) => {
                        this.setFileReaderValue(ev.target.result)
                    }
                    reader.readAsDataURL(file)
                }
            })

            if (resFile) {
                this.setValue(resFile)
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.uploader {
    &__wrapper {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        > input {
            position: absolute;
            left: 0;
            top: 0;
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            visibility: hidden;
        }
    }
    &__error {
        display: flex;
        align-items: center;
        margin-right: 35px;
        min-height: 36px;
    }

    &__files {
        margin-top: 10px;
        display: flex;
        flex-wrap: wrap;
    }
    &__file-image {
        position: relative;
        z-index: 1;
        width: 60px;
        height: 60px;
        margin-right: 10px;
        border-radius: 10px;
        position: relative;
        font-size: 0;
        overflow: hidden;
        border: 1px solid rgba(#38c948, 0.6);
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &::after {
            display: inline-block;
            content: " ";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: var(--color-cinder);
            opacity: 0;
            transition: opacity 0.25s ease-in-out;
        }
        &:hover {
            &::after {
                opacity: 0.7;
            }
            .uploader__file-remove {
                opacity: 1;
            }
        }
    }
    &__file-remove {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 2;
        transform: translate(-50%, -50%);
        font-size: 0;
        line-height: 1;
        opacity: 0;
        transition: opacity 0.25s ease;
    }
    &__file-info {
        margin-right: 10px;
        display: flex;
        align-items: center;
        svg {
            margin-left: 6px;
        }
    }
    &__file-delete {
        cursor: pointer;
        font-size: 0;
        line-height: 1;
        transition: opacity 0.25s ease-in-out;
        &:hover {
            opacity: 0.8;
        }
    }
    &__file-name,
    &__error-name {
        display: inline-block;
        font-weight: 400;
        font-size: 16px;
        line-height: calc(24 / 16);
    }

    &__error-name {
        color: var(--color-red);
    }
    &__trigger {
        display: flex;
        align-items: center;
        cursor: pointer;
        svg {
            margin-right: 4px;
        }
    }
}
</style>
