<template>
        <div class="container-wrapper container">
            <Sidebar />
            <main class="main">
                <Header />
                    <router-view />
                <Footer />
            </main>

            <!-- move to store -->
            <div class="page-loader" :class="[processingModal.isShown && 'is-active']">
                <UiLoader :loading="true" />
                <div class="page-loader__message" v-html="processingModal.text" />
            </div>

            <ActivationModal
                @setProcessing="(v) => (processingModal.isShown = v)"
                @setProcessingText="(v) => (processingModal.text = v)"
            />
            <SearchModal />
        </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex"
import Sidebar from "@/components/Layout/Sidebar.vue"
import Header from "@/components/Layout/Header.vue"
import Footer from "@/components/Layout/Footer.vue"
import ethEvents from "@/mixin/ethEvents"
import ActivationModal from "@/components/Matrix/ActivationModal.vue"
import SearchModal from "@/components/Search/Modal.vue"

export default {
    name: "AppLayoutDefault",
    computed: {
        ...mapGetters("loader", ["processingModal"]),
        ...mapState("user", ["currentUser"])
    },
    mixins: [ethEvents],
    components: {
        Header,
        Footer,
        Sidebar,
        ActivationModal,
        SearchModal
    },
    mounted() {
        this.initCrypto()
    },
    methods: {
        ...mapActions({ initCrypto: "crypto/init" })
    },
}
</script>

<style lang="scss" scoped>
.container-wrapper {
    display: flex;
    @media screen and (max-width: 991px) {
        flex-direction: column;
    }
}
.main {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
</style>
