import { render, staticRenderFns } from "./ButtonLight.vue?vue&type=template&id=71c2cf27&scoped=true&"
import script from "./ButtonLight.vue?vue&type=script&lang=js&"
export * from "./ButtonLight.vue?vue&type=script&lang=js&"
import style0 from "./ButtonLight.vue?vue&type=style&index=0&id=71c2cf27&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71c2cf27",
  null
  
)

export default component.exports