<template>
  <nav class="nav">
    <div class="nav-inner">
      <div class="nav-top">
        <ul class="nav-list nav-list-top list-reset">
          <!-- Links -->
          <!-- <li v-for="item in renderItems" :key="item.title" class="nav-list__item" @click="openFunc">
                        <router-link :to="{ name: item.link }" class="nav-list__link">
                            <div class="icon" :class="[`icon--${item.iClass}`]"></div>
                            <span>{{ $t(item.title) }}</span>
                        </router-link>
                    </li> -->

          <li
            v-for="item in renderItems"
            :key="item.title"
            class="nav-list__item"
            @click="openFunc"
          >
            <router-link :to="{ name: item.link }" class="nav-list__link">
              <BaseIcon :name="item.iconName" class="ico--indent" verAlign />
              <span>{{ $t(item.title) }}</span>
            </router-link>
          </li>

          <!-- Links settings and help -->

          <li class="nav-list__item" @click="openFunc">
            <router-link :to="{ name: 'settings' }" class="nav-list__link">
              <BaseIcon name="settings" class="ico--indent" verAlign />
              <span>{{ $t('menu.settings') }}</span>
            </router-link>
          </li>
          <li class="nav-list__item" @click="openFunc">
            <router-link :to="{ name: 'help' }" class="nav-list__link">
              <BaseIcon name="help" class="ico--indent" verAlign />
              <span>{{ $t('menu.help') }}</span>
            </router-link>
          </li>
        </ul>

        <!-- <ul class="nav-list-bottom list-reset"> -->
        <!-- <li
            v-for="item in extraItems"
            :key="item.name"
            class="nav-list__item"
            @click="openFunc"
          >
            <router-link :to="{ name: item.name }" class="nav-list__link">
              <BaseIcon :name="item.name" class="ico--indent" verAlign />
              <span>{{ $t('menu.settings') }}</span>
            </router-link>
          </li> -->

        <!-- <li class="nav-list__item" @click="openFunc">
            <router-link :to="{ name: 'settings' }" class="nav-list__link">
              <BaseIcon name="settings" class="ico--indent" verAlign />
              <span>{{ $t('menu.settings') }}</span>
            </router-link>
          </li>

          <li class="nav-list__item" @click="openFunc">
            <router-link :to="{ name: 'help' }" class="nav-list__link">
              <BaseIcon name="help" class="ico--indent" verAlign />
              <span>{{ $t('menu.settings') }}</span>
            </router-link>
          </li> -->

        <!-- <li class="nav-list__item">
                        <a href="" class="nav-list__link">
                            <div class="icon information"></div>
                            <span>Информация</span>
                        </a>
                    </li> -->
        <!-- </ul> -->
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseIcon from '@/components/UI/BaseIcon.vue'
export default {
  data() {
    return {
      items: [
        {
          title: 'menu.mainPage',
          iClass: 'main',
          link: 'dashboard',
          iconName: 'home',
          auth: true,
        },
        {
          title: 'menu.forceSystems',
          iClass: 'structure',
          link: 'classic',
          iconName: 'forceSystems',
          auth: true,
        },
        {
          title: 'menu.referral',
          iClass: 'referral',
          link: 'systems_referral',
          iconName: 'myTeam',
          auth: true,
        },
        {
          title: 'menu.academy',
          iClass: 'megaphone',
          iconName: 'academy',
          link: 'academy',
        },
      ],
      extraItems: [
        {
          name: 'settings',
        },
        {
          name: 'help',
        },
      ],
    }
  },
  props: {
    func: {
      type: Function,
    },
  },
  components: {
    BaseIcon,
  },
  computed: {
    renderItems() {
      if (this.isActiveWallet && this.getToken) {
        return this.items
      }

      return this.items.filter((x) => !x.auth)
    },
    ...mapGetters('crypto', ['isActiveWallet', 'getToken']),
  },
  methods: {
    openFunc() {
      if (window.innerWidth <= 991) {
        this.func()
      }
    },
  },
}
</script>

<style lang="scss">
.nav {
  align-self: flex-start;
  justify-content: flex-start;
  @media (max-width: 991px) {
    padding-left: var(--indent-30);
    padding-top: var(--indent-20);
  }
  &-list__item {
    margin-bottom: var(--indent-24);
  }
  &-bottom {
    margin-top: auto;
    display: flex;
    flex-direction: column;
  }
  &-list__link {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--color-black-500);
    font-size: var(--font-14);
    line-height: var(--line-20);
    font-weight: var(--font-600);
    cursor: pointer;
    opacity: 0.6;

    transition: color 500ms, opacity 500ms;
    &.router-link-active {
      color: var(--color-black-100);
      opacity: 1;
      cursor: pointer;
    }
    &:hover {
      color: var(--color-black-100);
      opacity: 1;
    }
  }

  &-list-top,
  &-sign-out {
    margin-bottom: var(--indent-15);
    @media screen and (max-width: 991px) {
      border: 0;
    }
  }
  &-sign-out {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.hide-sidebar {
  width: 29px;
  height: 29px;
  border-radius: 100%;
  border: 2px solid var(--color-white);
  margin-left: auto;
  @media screen and (max-width: 991px) {
    display: none;
  }
}

.ico--indent {
  margin-right: var(--indent-15);
}
</style>
