<template>
  <div class="sidebar__bottom">
    <div class="sidebar__force-id" @click="isOpen = !isOpen">
      <div class="sidebar__force-id-ico">
        <img
          class="sidebar__force-id-ico"
          src="../../../public/assets/dashboard/force-id-user.svg"
          alt="Logo"
        />
      </div>
      <div class="sidebar__force-id-text">Force ID</div>
      <div class="sidebar__force-id-btn-inner">
        <BaseIcon name="arrowRight" />
      </div>
    </div>

    <ButtonLight :text="$t('menu.copyLink')" @event="handleClipboardCopy" />
    <!-- <SocialLinks /> -->
    <ul class="sidebar__bottom-popup" v-show="isOpen">
      <li class="sidebar__bottom-logout" @click="logout">
        <BaseIcon
          class="sidebar__bottom-logout--mr"
          name="logOut"
          verAlign
        />Log out
      </li>
    </ul>
  </div>
</template>

<script>
import BaseIcon from '@/components/UI/BaseIcon.vue'
import ButtonLight from '@/components/UI/ButtonLight.vue'
import SocialLinks from '@/components/UI/SocialLinks.vue'
import { copyToClipboard } from '@/helpers/clipboard'
import { mapState } from 'vuex'

export default {
  components: {
    BaseIcon,
    ButtonLight,
    SocialLinks,
  },

  data() {
    return {
      isOpen: false,
    }
  },

  computed: {
    ...mapState('user', ['currentUser']),

    refLink() {
      const code = this.currentUser.name
      return `https://${window.location.host}/r/${code}`
    },
  },

  methods: {
    handleClipboardCopy() {
      if (!this.currentUser) return

      copyToClipboard()
      navigator.clipboard
        .writeText(this.refLink)
        .then(() => {
          this.$toast.success(`${this.refLink} ${this.$t('account.copied')}`)
        })
        .catch(() => {
          this.$toast.error(`${this.$t('account.copyError')}`)
        })
    },
    logout() {
      this.$emit('logout')
      this.isOpen = false
    },
  },

  mounted() {
    window.addEventListener('click', (event) => {
      if (!this.$el.contains(event.target)) {
        this.isOpen = false
      }
    })
  },
}
</script>

<style lang="scss">
.sidebar {
  &__bottom {
    font-family: 'Poppins', sans-serif;
    position: relative;
  }
  &__force-id {
    display: flex;
    align-items: center;
    position: relative;
    padding: var(--indent-8);
    background: var(--color-black-750);
    color: var(--color-black-500);
    border-radius: var(--indent-8);
    transition: color 500ms;
    margin-bottom: var(--indent-8);
    &:hover {
      color: var(--color-black-100);
      cursor: pointer;
    }
  }
  &__force-id-ico {
    display: flex;
    align-items: center;
    border-radius: var(--radius-50);
    margin-right: var(--indent-8);
  }
  &__force-id-text {
    font-size: 14px;
    font-weight: 600;
  }
  &__force-id-btn-inner {
    position: absolute;
    right: 8px;
    top: 15px;
  }
  &__bottom-popup {
    min-width: 200px;
    position: absolute;
    z-index: 10;
    left: 0;
    top: -63px;
    padding: var(--indent-16);
    background: var(--color-black-800);
    border-radius: var(--indent-8);
    box-shadow: 0px 0px 10px 0px rgb(0 0 0 / 30%);
    transition: all 600ms;
    @media (max-width: 991px) {
      min-width: 260px;
      right: 0;
      top: -63px;
    }
  }
  &__bottom-logout {
    display: flex;
    align-items: center;
    font-size: var(--font-14);
    line-height: var(--line-20);
    font-weight: var(--font-600);
    color: var(--color-red);
    transition: color 500ms;
    cursor: pointer;
    &:hover {
      color: var(--color-orange-900);
    }
  }
  &__bottom-logout--mr {
    margin-right: var(--indent-12);
  }
}
</style>
