const users = "/users"
const ticket = "/ticket"

export default {
    users: {
        getAll: `${users}`,
        getById: (id) => `${users}/${id}`,
        isAccountByHash: (hash) => `${users}/methods/isaccount?id=${hash}`,
        getByField: `${users}/methods/getaccaount`,
        getBatchAccounts: `${users}/methods/get-accounts`,
        authMessage: `${users}/auth/message`,
        authVerify: `${users}/auth/verify`,
        register: `/new/new_user`,
        isLevelActive: "/users/methods/islevelactive",
        getParentsCount: "users/methods/getparentscount",
        setLevel: "/users/methods/setlevel",
        getTransactions: `/users/methods/transactions`,
        sendTransaction: "/transactions/send-transaction",
    },
    ticket: {
        topics: `${ticket}/topics`,
        create: `${ticket}/create`,
        list: `${ticket}/my`,
        faq: `${ticket}/faq`,
        getById: (id) => `${ticket}/${id}`,
        close: (id) => `${ticket}/${id}/close`,
        getMessages: (id) => `${ticket}/${id}/messages`,
        createMessage: (id) => `${ticket}/${id}/send-message`,
    },
}
