// import "@babel/polyfill";
import "@/assets/scss/normalize.scss"
import "@/assets/scss/app.scss"
import "@/assets/scss/spa.scss"

// @TODO VITE
// import "mutationobserver-shim"
import Vue from "vue"
import router from "./router"
import store from "./store"
import initPlugins from "./plugins"
import i18n from "./plugins/i18n"

// import "~@/assets/scss/backCustom.scss";

import App from "./App.vue"

import * as components from "@/components/UI"

for (const name in components) {
  Vue.component(name, components[name])
}

// init plugins from /plugins/index.js list
initPlugins(Vue)

Vue.config.productionTip = false

import * as filters from "@/filters"
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

if (localStorage.getItem("clearStorage") !== process.env.VUE_APP_STORAGE_CLEAR_APP_VERSION) {
  console.log("STORAGE CLEARED")
  localStorage.clear()
  localStorage.setItem("clearStorage", process.env.VUE_APP_STORAGE_CLEAR_APP_VERSION)
}

import * as svgicon from 'vue-svgicon';
Vue.use(svgicon, {
  classPrefix: 'AppIcon-',
});

const vm = new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount("#app")

// @TODO delete
export { vm }
