<template>
    <!--  <component :is="layout">-->
    <!--    <slot />-->
    <!--  </component>-->
    <router-view />
</template>

<script>
import { LSTORAGE } from "@/config/constants.js"
import { setLocalStorageElement } from "@/helpers/localstorage.js"

// const defaultLayout = "AppLayoutDefault"
export default {
    name: "AppLayout",
    components: {},
    computed: {},
    mounted() {
        setLocalStorageElement(LSTORAGE.visited, true)
    }
}
</script>
