<template>
  <div class="bell">
    <BaseIcon name="bell" verAlign />
    <span class="bell__count">{{ count }}</span>
  </div>
</template>

<script>
import BaseIcon from '@/components/UI/BaseIcon.vue'

export default {
  props: ['count'],
  components: { BaseIcon },
}
</script>

<style lang="scss">
.bell {
  background: var(--color-black-800);
  padding: var(--indent-4);
  border-radius: var(--indent-8);
  margin-right: var(--indent-8);
  display: flex;
  align-items: center;
  justify-content: center;
  &__count {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--font-12);
    width: var(--indent-20);
    height: var(--indent-20);
    margin-left: 6px;
    margin-right: 2px;
    border-radius: var(--radius-50);
    font-weight: var(--font-600);
    color: var(--color-white);
    background: var(--color-purple);
  }
}
</style>