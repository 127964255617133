import { $api } from "@/plugins/Axios"
import endpoint from "@/config/api-endpoint"
import { vm } from "@/main.js";
import { mapApiError, mapData, handleBooleanResponce } from "./helpers"

export const getUsersService = async (request) => {
    try {
        const { data } = await $api.get(endpoint.users.getAll)

        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}

export const getUserByIdService = async (request) => {
    try {
        const { data } = await $api.get(endpoint.users.getById(request.id), {
            ...request,
        })

        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}

export const getUserByFieldService = async (request) => {
    try {
        const { data } = await $api.post(endpoint.users.getByField, {
            ...request,
        })

        if (data && (data.status === "error" || data.users === null)) {
            const error = new Error(`${vm.$t("api.helpers.notFound")}`)
            error.status = 404
            throw error
        }

        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}

export const getUsersBatchService = async (request) => {
    try {
        const { data } = await $api.post(endpoint.users.getBatchAccounts, {
            ...request,
        })

        if (data && (data.status === "error" || data.users === null)) {
            const error = new Error(`${vm.$t("api.helpers.notFound")}`)
            error.status = 404
            throw error
        }

        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}

export const isValidAccountService = async (request) => {
    try {
        const { data } = await $api.get(endpoint.users.isAccountByHash(request.hash), {
            ...request,
        })

        return handleBooleanResponce(data, `${vm.$t("api.helpers.notFound")}`)
    } catch (error) {
        return [mapApiError(error), null]
    }
}

export const getAuthMessageService = async (request) => {
    try {
        const { data } = await $api.get(endpoint.users.authMessage, {
            ...request,
        })

        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}

export const verifyAuthMessageService = async (request) => {
    try {
        const { data } = await $api.post(endpoint.users.authVerify, {
            ...request,
        })

        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}

export const registerAccountService = async (request) => {
    try {
        const { data } = await $api.post(endpoint.users.register, {
            ...request,
        })

        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}

export const isLevelActiveService = async (request) => {
    try {
        const { data } = await $api.post(endpoint.users.isLevelActive, {
            ...request,
        })

        return handleBooleanResponce(data, `${vm.$t("api.helpers.notActivated")}`)
    } catch (error) {
        return [mapApiError(error), null]
    }
}

export const getParentsCountService = async (request) => {
    try {
        const { data } = await $api.post(endpoint.users.getParentsCount, {
            ...request,
        })

        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}

export const setLevelService = async (request) => {
    try {
        const { data } = await $api.post(endpoint.users.setLevel, {
            ...request,
        })

        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}

export const getTransactionsService = async (request) => {
    try {
        const { data } = await $api.get(endpoint.users.getTransactions, {
            params: { ...request },
        })

        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}

export const sendTransactionService = async (request) => {
    try {
        const { data } = await $api.post(endpoint.users.sendTransaction, {
            ...request,
        })

        return [null, mapData(data)]
    } catch (error) {
        return [mapApiError(error), null]
    }
}
