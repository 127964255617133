export const DICT = {
    SERVICE_MODE: process.env.VUE_APP_SERVICE_MODE || false,
    PROVIDER: process.env.VUE_APP_CRYPTO_PROVIDER,
    CHAIN_ID: Number(process.env.VUE_APP_CRYPTO_CHAIN),
    IS_TESTNET: false,
    CHAIN_NAME: ["Polygon Mainnet", "Polygon Mumbai Testnet"],
    NATIVE_CURRENCY_SYMBOL: "MATIC",
    EXPLORER_URLS: [["https://polygonscan.com/"], ["https://mumbai.polygonscan.com/"]],

    CREATOR: process.env.VUE_APP_CRYPTO_CREATOR,
    CONTRACT_MAIN: process.env.VUE_APP_CRYPTO_CONTRACT_MAIN,
    CONTRACT_SFC: process.env.VUE_APP_CRYPTO_CONTRACT_SFC,
    CONTRACT_SFC_NEW: process.env.VUE_APP_CRYPTO_CONTRACT_SFC_NEW,
    CONTRACT_SFC2: process.env.VUE_APP_CRYPTO_CONTRACT_SFC2,
    CONTRACT_MFS: process.env.VUE_APP_CRYPTO_CONTRACT_MFS,

    CONTRACT_CONTROL: process.env.VUE_APP_CRYPTO_CONTRACT_CONTROL,
    CONTRACT_REGISTRY: process.env.VUE_APP_CRYPTO_CONTRACT_REGISTRY,

    DEFAULT_GAS_LIMIT: 500000,
    DEFAULT_GAS_PRICE: "40000000000",
    DEFAULT_GAS_MAX: "42000000000",
    ESTIMATED_GAS_INCREASE: 1.1, // gas limit multiply
    ESTIMATED_GAS_PRIORIY: 1.4, // ~ aggressive
    ESTIMATE_GAS_MAX_PER_BASE: 2 // defaults to 2
}

console.log("DICT", DICT)

export const LANGS = ["ru", "en", "cn"]

export const LSTORAGE = {
    lang: "lang",
    visited: "force_visit",
    referral: "force_upline",
    connected: "force_wallet_connected",
    wallet: "force_wallet_type",
    walletconnect: "walletconnect",
    lostTx: "force_lost_tx",
    token: "force_remember_token"
}
