<template>
    <main class="main">
        <router-view />
    </main>
</template>

<script>
import Header from "@/components/Layout/Header.vue"
import Footer from "@/components/Layout/Footer.vue"

export default {
    components: {
        Header,
        Footer,
    },
    mounted() {
        const plugin = document.createElement("script")
        plugin.setAttribute("src", "//code.jivo.ru/widget/b5Vv0BBS3n")
        plugin.async = true
        document.head.appendChild(plugin)
    },
}
</script>

<style lang="scss" scoped>
.main {
    width: 100%;
    min-height: 100vh;
    height: auto;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}
</style>
