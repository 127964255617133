<template>
    <div class="datepicker" :class="[{ 'has-error': error }, theme, variant]">
        <UiLabel v-if="label" :for="_uid" :label="label" :error="error" />

        <v-date-picker
            :value="value"
            :masks="masks"
            :locale="locale"
            :input-debounce="0"
            :popover="popover"
            v-bind="$attrs"
            @input="setValue"
            v-on="$listeners"
        >
            >
            <template #default="{ inputValue, inputEvents }">
                <UiInput
                    v-mask="'##.##.##'"
                    :value="inputValue"
                    theme="request"
                    icon="calendar"
                    icon-position="right"
                    :placeholder="placeholder"
                    :error="error"
                    type="text"
                    v-on="inputEvents"
                    @onChange="(v) => (inputValue = v)"
                />
            </template>
        </v-date-picker>
    </div>
</template>

<script>
export default {
    name: "DatePicker",

    props: {
        value: {
            type: [String, Date],
            required: false,
        },
        label: {
            type: String,
            required: false,
        },
        placeholder: {
            type: String,
            required: false,
            default: "Выберите дату",
        },
        type: {
            type: String,
            default: "text",
        },
        error: {
            type: [String, Boolean],
            required: false,
        },
        theme: {
            type: String,
            required: false,
        },
        variant: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            masks: {
                input: "DD.MM.YY",
                weekdays: "WW",
            },
            popover: {
                // visibility: "click",
                positionFixed: true,
                placement: "bottom",
            },
            format: "",
        }
    },

    methods: {
        setValue(v) {
            // Date, Array[Date], Object
            this.$emit("onChange", v)
        },
    },
    computed: {
        locale() {
            const $locale = this.$i18n.locale
            if ($locale === "cn") return "zh-CN"
            return $locale
        },
    },
}
</script>

<style lang="scss" scoped>
.datepicker {
    position: relative;
}
</style>

<style lang="scss">
.datepicker {
    .vc-popover-content-wrapper {
        min-width: inherit !important;
        width: inherit !important;
        max-width: inherit !important;
    }
    .vc-container {
        max-width: inherit;
        width: 100%;
        box-shadow: 0px 8px 16px rgba(62, 19, 77, 0.07);
        border: 1px solid rgba(#38c948, 0.6);
        background: var(--color-cinder);
        border-radius: 20px;
    }
    .vc-pane-container,
    .vc-pane-layout {
        padding: 0;
        margin: 0;
        box-shadow: none;
    }
    .vc-pane {
        padding: 0px 28px 32px;
    }

    .vc-header {
        padding: 32px 40px 0px;
    }

    .vc-title {
        color: white;
        font-weight: 400;
        font-size: 18px;
        line-height: calc(27 / 18);
    }

    .vc-weekday {
        font-weight: 400;
        font-size: 16px;
        line-height: calc(24 / 16);
        color: #c5c5c5;
        padding: 10px 0 8px;
        margin-bottom: 12px;
        text-transform: capitalize;
        border-bottom: 1px solid #c5c5c5;
    }

    .vc-weeks {
        // padding: 0 32px 32px;
        padding: 0;
        max-width: 275px;
        min-width: 275px;
    }

    .vc-day {
        width: 40px;
        height: 36px;

        &.is-not-in-month {
            opacity: 1;
            .vc-day-content {
                color: #c5c5c5;
            }
        }
        & > .vc-day-content {
            opacity: 1;
        }
        &:nth-child(49),
        &:nth-child(48),
        &:nth-child(47),
        &:nth-child(46),
        &:nth-child(45),
        &:nth-child(44),
        &:nth-child(43) {
            display: none;
        }
    }

    .vc-day-content {
        width: 40px;
        height: 36px;
        font-weight: 400;
        font-size: 14px;
        line-height: calc(21 / 14);
        color: white;
        border-radius: 5px;
        &:hover {
            background: var(--color-charade);
        }
    }
    .vc-highlight {
        width: 40px;
        height: 36px;
        background: var(--color-yellow) !important;
        border-radius: 5px !important;
    }

    .vc-arrows-container {
        top: 32px;
        padding: 10px 32px;
    }

    .vc-arrow {
        svg {
            display: none;
        }
        width: 40px;
        height: 6px;
        background: url("/assets/account/arrow-cal-left.svg") no-repeat;
        background-position: center;
        // &.is-left {
        // }
        &.is-right {
            transform: rotate(180deg);
        }

        &:hover {
            opacity: 0.5;
        }
    }

    .vc-popover-caret {
        display: none;
    }

    &:focus,
    &:hover {
        .input__input {
            input {
                transition: all ease-in-out;
                // border-radius: 40px 40px 0px 0px !important;
                border-color: transparent !important;
            }
        }
    }
}
</style>
