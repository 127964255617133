<template>
        <!-- <div class="hdr">
            <div class="hdr__firce-id">@{{ currentUser.name }}</div>
            <div class="hdr__items">
                <Language :selected="selected" @select="optionSelect" :options="options" />
                <div class="hdr__wallet">
                    <BaseIcon name="wallet" verAlign/>
                </div>
                <Bell count="21"/>
            </div>
        </div> -->
        
        <header class="header" :class="[simple && 'is-simple']">
            <a class="header__logo" href="/">
                <img src="/assets/account/logo.png" alt="logo force" />
            </a>

            <template v-if="simple">
                <a href="#" @click="(e) => handleAuthClick('login', e)" class="header__connect btn-reset">
                    {{ $t("landing.menu.login") }}
                </a>
            </template>

            <div class="header__inner header-bottom" style="width: 100%">

                <div class="breadcrumbs">
                 <BreadCrumbs />
                </div>
                <div class="" v-if="isConnecting">
                    <UiLoader :loading="true" />
                </div>
                
                <div class="header__items" v-else-if="isConnected">
                    <Language :selected="selected" @select="optionSelect" :options="options" />
                <div class="header__wallet">
                    <BaseIcon name="wallet" verAlign/>
                </div>
                    <Bell count="21"/>

                    <!-- <div class="balance-numbers align-center ml-2" @click="handleClipboardCopy(getBalance.bnb)">
                        <div class="balance-image align-center justify-center">
                            <svg-vue icon="symbol_matic-white" />
                        </div>
                        <p class="balance-graph">{{ getBalance.bnb }} MATIC</p>
                    </div>
                    <div class="balance-numbers" @click="handleClipboardCopy(getBalance.busd)">
                        <div class="balance-image">
                            <svg-vue icon="symbol_dai-white" />
                        </div>
                        <p class="balance-graph">{{ getBalance.busd }} DAI</p>
                    </div>
                    <div class="balance-numbers" @click="handleClipboardCopy(accountTrim)">
                        <div class="balance-image">
                            <svg-vue icon="wallet-white" />
                        </div>
                        <p class="balance-graph ma-0">
                            {{ accountTrim }}
                        </p>
                    </div> -->
                </div>
                <ButtonDark :text="$t('connectWallet')" @event="openModal" v-else />
                <ConnectModal />

                <!-- <button class="connect-wallet" v-else>
                    <span @click="openModal" elevation="2" large>{{ $t("connectWallet") }}</span>
                </button> -->
            </div>
        </header>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex"
import ConnectModal from "@/components/Layout/ConnectModal.vue"
import Language from "../UI/Language.vue"
import { isServiceMode } from "@/helpers/dev"
import { nullEmptyHash } from "@/helpers/matrix"
import { getConnectTypeFromSelectWallet } from "@/helpers/params"
import { copyToClipboard } from "@/helpers/clipboard"
import BaseIcon from '@/components/UI/BaseIcon.vue'
import Bell from '@/components/UI/Bell.vue'
import ButtonDark from '@/components/UI/ButtonDark.vue'
import BreadCrumbs from '@/components/UI/BreadCrumbs.vue'

export default {
    data() {
        return {
            selected: localStorage.getItem("lang") || "en",
            options: [
                { name: "ru", value: 1, active: localStorage.getItem("lang") === "ru" },
                { name: "en", value: 2, active: localStorage.getItem("lang") === "en" },
                { name: "cn", value: 3, active: localStorage.getItem("lang") === "cn" }
            ],
        }
    },
    components: {
        ConnectModal,
        Language,
        BaseIcon,
        Bell,
        ButtonDark,
        BreadCrumbs,
    },
    props: {
        simple: Boolean
    },
    computed: {
        accountTrim() {
            if (!this.getAccount) return ""
            return this.getAccount.substr(0, 4) + "..." + this.getAccount.substr(-3, 3)
        },
        ...mapState("user", ["currentUser"]),
        ...mapGetters("crypto", [
            "getBalance",
            "isConnecting",
            "isConnected",
            "getAccount",
            "getParent",
            "getConnectionWallet"
        ])
    },
    methods: {
        ...mapMutations("ui", ["setConnectionModal"]),
        openModal: function() {
            if (isServiceMode()) {
                this.$router.push({ name: "service" })
            } else {
                this.setConnectionModal({ visible: true, type: "connect" })
            }
        },
        optionSelect(option) {
            this.selected = option.name
        },
        handleAuthClick(type, e) {
            e.preventDefault()
            this.setConnectionModal({ visible: true, type: "select", params: { type: "login_check" } })
        },
        handleClipboardCopy(text) {
            if (!text) return

            copyToClipboard(text)
                .then(() => {
                    this.$toast.success(`${text} ${this.$t("account.copied")}`)
                })
                .catch(() => {
                    this.$toast.error(`${this.$t("account.copyError")}`)
                })
        },
        async processAuth() {
            const [connectErr, connectRes] = await this.checkConnect()
            if (connectErr) this.$swal(connectErr)

            const hasNoParent = nullEmptyHash(this.getParent) === null

            if (!hasNoParent) {
                // window.location.href = "/dashboard"
                await this.$router.push("/dashboard")
            } else {
                this.$swal(this.$t("connectModal.swal.notRegistered"))
            }
        },
        ...mapActions("crypto", ["checkConnect"])
    },
    watch: {
        getConnectionWallet(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                const type = getConnectTypeFromSelectWallet(newVal)
                if (type === "login_check") {
                    this.processAuth()
                }
            }
        }
    }
}
</script>
