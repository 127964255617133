<template>
  <button :class="classList" @click="event">{{ text }}</button>
</template>

<script>
export default {
  props: ["text", "classes"],
  emits: ["event"],
  methods: {
    event() {
      this.$emit("event");
    },
  },

  computed: {
    
    classList() {
      if (!this.classes) {
        return "button-light";
      } else {
        return this.classes.split(' ');
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.button-light {
  background: var(--color-orange-900);
  padding: var(--indent-14);
  border-radius: var(--indent-8);
  font-size: var(--font-14);
  line-height: var(--line-20);
  letter-spacing: 0.5px;
  font-weight: var(--font-600);
  color: var(--color-white);
  min-width: 100%;
  transition: background 500ms;
  cursor: pointer;
  &:hover {
    background: var(--color-orange-800);
  }
}
</style>