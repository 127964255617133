import axios from "axios"
import { Log, SentryLog } from "@/helpers/dev"
import { getLocalStorageElement } from "@/helpers/localstorage"
import { LSTORAGE } from "@/config/constants"
import store from "@/store"

const handleError = async (error) => {
    if (error.response && !error.response.config.url.includes("auth")) {
        if (parseInt(error.response && error.response.status) === 401) {
            await store.dispatch("crypto/logOut")
        }
    }
}

// create instance
const api = axios.create({
    baseURL: `${process.env.VUE_APP_BASE_API_PROTOCOL}://${process.env.VUE_APP_BASE_API_URL}/api/v1`,
    // withCredentials: true,
    headers: {
        common: {
            "Content-Type": "application/json"
            // Accept: "application/json",
            // "X-Requested-With": "XMLHttpRequest",
        }
    }
})

api.interceptors.request.use(
    (x) => {
        const lsToken = getLocalStorageElement(LSTORAGE.token)

        x.headers = {
            ...x.headers,
            Authorization: `Bearer ${lsToken}`
        }

        Log(`${x.method.toUpperCase()} | ${x.url}`, x.params, x.data)

        return x
    },
    async (error) => {
        await handleError(error)
        SentryLog(error)
        return Promise.reject(error)
    }
)

api.interceptors.response.use(
    (x) => {
        Log(`${x.status} | ${x.config.url}`, x.data)

        return x
    },
    async (error) => {
        await handleError(error)
        SentryLog(error)
        return Promise.reject(error)
    }
)

const initAxios = (app) => {
    app.prototype.$api = api
}

export { initAxios, api as $api }
