<template>
  <div class="social-links">
    <a
      v-for="(item, idx) in linksList"
      :key="idx"
      :href="item.link"
      target="_blank"
    >
      <BaseIcon :name="item.name" />
    </a>
  </div>
</template>
<script>
import BaseIcon from '@/components/UI/BaseIcon.vue'

export default {
  components: { BaseIcon },

  data() {
    return {
      linksList: [
        {
          name: 'socIcoYotube',
          link: 'https://www.youtube.com/@MetaFORCESpace',
        },
        {
          name: 'socIcoDiscord',
          link: 'https://discord.gg/yZYzwpJeWg',
        },
        {
          name: 'socIcoFacebook',
          link: 'https://t.me/MetaForceSpace',
        },
        {
          name: 'socIcoTwitter',
          link: 'https://twitter.com/MetaForceSpace',
        },
        {
          name: 'socIcoInstagram',
          link: 'https://www.instagram.com/metaforcespace/',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.social-links {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0;
}
</style>
