import Vue from "vue"
import Vuex from "vuex"
import crypto from "./modules/crypto"
import user from "./modules/user"
import ticket from "./modules/ticket"
import ui from "./modules/ui"
import post from "./modules/post"
import category from "./modules/category"
import loader from "./modules/loader"

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        crypto,
        user,
        ticket,
        ui,
        post,
        category,
        loader
    }
})
