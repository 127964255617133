<template>
    <div>
        <UiModal :visible="modalVisible" :title="$t('search.title')" @closeModal="closeModal">
            <form class="search-modal__form search-form" @submit.prevent="handleSearch">
                <div class="search-form__label">{{ $t("search.label") }}</div>
                <input class="search-form__input" v-model="search" type="text" placeholder="" />
                <button type="submit" class="search-form__submit">{{ $t("search.submit") }}</button>
            </form>
            <div class="search-modal__error" v-if="error">{{ $t("search.error") }}</div>
        </UiModal>

        <div class="search-helper" :class="[haveForceInUrl && 'show']">
            <form class="search-form" @submit.prevent="handleSearch">
                <div class="search-form__label">{{ $t("search.labelDemo") }}</div>
                <input
                    class="search-form__input"
                    :class="[error && 'error']"
                    v-model="search"
                    type="text"
                    :placeholder="$t('search.placeholder')"
                />
                <button type="submit" class="search-form__submit" :class="[!searchValid && 'disabled']">
                    {{ $t("search.go") }}
                </button>
                <button class="search-form__submit is-clear" @click="exitDemo">
                    <svg-vue icon="close-black" />
                </button>
            </form>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from "vuex"

export default {
    data() {
        return {
            loading: false,
            error: false,
            search: "",
        }
    },
    mounted() {
        this.search = this.haveForceInUrl || ""
    },
    computed: {
        modalVisible() {
            return this.searchModal.visible && this.searchModal.type === "modal"
        },
        haveForceInUrl() {
            try {
                return this.$router.currentRoute.query.account
            } catch {
                return false
            }
        },
        searchValid() {
            return this.search.trim().length >= 1
        },
        ...mapGetters("ui", ["searchModal"]),
    },
    methods: {
        async handleSearch() {
            if (this.loading) return
            this.error = false
            this.loading = true

            try {
                let userResponse = await this.guessUser(this.search)
                if (!userResponse) throw new Error()

                // this.$router.push({ name: "dashboard", query: { account: userResponse.name } })
                location.href = `dashboard?account=${userResponse.name}`
                this.closeModal()
                // document.body.classList.contains("hidden")
                //     ? document.body.classList.remove("hidden")
                //     : document.body.classList.add("hidden")
            } catch (err) {
                console.log("err")
                this.error = true
            }

            this.loading = false
        },
        exitDemo() {
            // this.$router.replace({ query: null })
            // this.$router.push(this.$route.path)
            window.location.href = "/dashboard"

            this.closeModal()
        },
        closeModal() {
            this.setSearchModal({ visible: false })
        },
        ...mapActions("user", ["guessUser"]),
        ...mapMutations("ui", ["setSearchModal"]),
    },
    watch: {
        activationModalVisible() {
            this.search = ""
        },
        search() {
            this.error = false
        },
    },
}
</script>

<style lang="scss" scoped>
.search-modal {
    &__form {
        margin-top: 24px;
    }
    &__error {
        margin-top: 8px;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #ff381d;
        text-align: left;
    }
}

.search-helper {
    position: absolute;
    z-index: 9;
    top: 0;
    width: 724px;
    right: calc((100vw - 1200px) / 2);
    background: #fedd00;
    border-radius: 0px 0px 10px 10px;
    padding: 15px 20px;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.25s ease-in-out;
    &.show {
        opacity: 1;
        pointer-events: all;
    }
    @media screen and (max-width: (1250px)) {
        right: calc((100vw - 932px) / 2);
        width: 540px;
    }
    @media screen and (max-width: (991px)) {
        right: calc((100vw - 728px) / 2);
        top: 42px;
        width: 540px;
        border-radius: 0px 0px 10px 10px;
    }
    @media screen and (max-width: (767px)) {
        right: 15px;
        left: 15px;
        width: calc(100% - 30px);
        padding: 8px 14px;
    }
    .search-form {
        &__label {
            color: #161620;
        }
        &__submit {
            background: white;
            width: auto;
            &.disabled {
                background-color: #c5c5c5;
            }
            &:hover {
                background-color: rgba(white, 0.8);
            }
        }
    }
}

.search-form {
    display: flex;
    align-items: center;
    &__label {
        flex: 0 0 auto;
        margin-right: 15px;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
        color: var(--color-silver);
        @media screen and (max-width: (767px)) {
            display: none;
        }
    }
    &__input {
        -webkite-appearance: none;
        flex: 1 1 auto;
        min-width: 1px;
        margin-right: 15px;
        background: #23222c;
        border-width: 0;
        border-radius: 10px;
        padding: 7px 15px;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
        color: #ffffff;
        outline: none;
        &::placeholder {
            color: #bcbcbc;
        }
        &:focus,
        &:active {
            border-color: transparent !important;
        }
        &.error {
            border: 1px solid tomato !important;
        }
    }
    &__submit {
        flex: 0 0 auto;
        min-width: 1px;
        width: 100%;
        max-width: 100px;
        background-color: var(--color-yellow);
        color: var(--color-cinder);
        border: 0;
        transition: background-color 0.25s ease-in-out;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        padding: 8px 14px 8px;
        border-radius: 10px;
        &.is-clear {
            margin-left: 15px;
        }
        &.disabled {
            pointer-events: none;
            background-color: #c5c5c5;
        }
        &:hover {
            background-color: #ffe851;
        }
    }
}
</style>
