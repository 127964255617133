<template>
    <div class="site-container">
        <l-header />
        <router-view />
        <l-footer />
    </div>
</template>

<script>
import LHeader from "@/components/Landing/partials/LHeader.vue"
import LFooter from "@/components/Landing/partials/LFooter.vue"

export default {
    name: "AppLayoutLanding",
    components: {
        LHeader,
        LFooter
    },
    mounted() {
        import("../helpers/landing.js")
        import("../helpers/switcher.js")
    }
}
</script>

<style lang="scss">
@import "~@/assets/scss/mixins";
@import "~aos/dist/aos.css";
@import "~sweetalert2/src/variables";
@import "~sweetalert2/src/sweetalert2";

@font-face {
    font-display: swap;
    font-family: "Dodger-super-Italic";
    src: url("~@/assets/Dodger-Super-Italic.woff2") format("woff2");
    font-style: italic;
    font-weight: 400;
}

:root {
    --l-god-gray: #0e0e0e;
    --l-color-black: #010101;
    --l-color-white: #fff;
    --l-color-silver: #c5c5c5;
    --l-color-jafa: #ef9e40;
    --l-color-linear: linear-gradient(180deg, #eeb445 0%, #ef9e40 100%);
    --l-font-size-xs: 14px;
    --l-font-size-s: 16px;
    --l-font-size-m: 18px;
    --l-font-size-l: 20px;
    --l-font-size-l-plus: 24px;
    --l-font-size-xl: 36px;
    --l-font-size-xxl: 48px;
    --l-font-size-xxxl: 64px;
    --l-offset-size-xl: 30px;

    --l-color-yellow: #fedd00;
    --l-color-emerald: #38c948;
    --l-color-everglade: #1d3f21;
    --l-color-cinder: #161620;
    --l-color-charade: #23222c;
    --l-color-silver: #bcbcbc;
    --l-color-tulip: #eeb445;
    --l-color-mustard: #ffe660;

    --l-color-gray: #828282;
    // offset
    --l-main-offset: 30px;
    --l-second-offset: 20px;
    --l-third-offset: 40px;
}
//
//body {
//    font-family: "Raleway", sans-serif;
//    color: var(--l-color-white);
//    background-color: var(--l-color-black);
//    font-weight: 300;
//    font-size: var(--l-font-size-s);
//    line-height: 130%;
//    &.hidden {
//        overflow: hidden;
//    }
//    @media screen and (max-width: 600px) {
//        font-size: 14px;
//    }
//}

.site-container {
    font-family: "Raleway", sans-serif;
    color: var(--l-color-white);
    background-color: var(--l-color-black);
    font-weight: 300;
    font-size: var(--l-font-size-s);
    line-height: 130%;
    &.hidden {
        overflow: hidden;
    }
    @media screen and (max-width: 600px) {
        font-size: 14px;
    }

    overflow: hidden;
    // background-image: url("@/assets/images/landing/force-bg.png");
    background-repeat: no-repeat;
    background-size: cover;

    @media screen and (min-width: 1600px) {
        // background-size: 100% 100%;
    }

    @include mobile {
        background-image: none;
    }

    position: relative;

    &:after {
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        z-index: 4;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.25s ease-in-out;
    }
    &.show {
        &:after {
            opacity: 1;
            visibility: visible;
        }
    }

    img {
        max-width: 100%;
    }

    a {
        display: inline-block;
        text-decoration: none;
        color: inherit;
    }

    //.btn-reset {
    //    border: none;
    //    padding: 0;
    //    background: transparent;
    //    cursor: pointer;
    //}

    .list-reset {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .container {
        margin: 0 auto;
        max-width: 1110px;

        @media screen and (max-width: 1200px) {
            width: 932px;
        }

        @media screen and (max-width: 991px) {
            width: 728px;
        }

        @media screen and (max-width: 767px) {
            width: calc(100% - 30px);
        }
    }

    .large-text {
        font-size: var(--l-font-size-m);
        line-height: 130%;

        @include mobile {
            font-size: 16px;
            line-height: 20px;
        }
    }

    .glob-btn {
        background-color: inherit;
        font-size: var(--l-font-size-l);
        line-height: 23px;
        font-weight: 500;
        padding: 15px 25px;
        color: inherit;

        @include mobile-s {
            font-size: 16px;
            line-height: 19px;

            text-align: center;
        }

        @media screen and (max-width: 425px) {
            width: 100%;
        }

        &--primary {
            transition: box-shadow 0.25s ease-in-out;
            background: linear-gradient(180deg, #eeb445 0%, #ef9e40 100%);
            box-shadow: 0 0 6px #ef9e40;
            border-radius: 10px;

            &:hover {
                box-shadow: 0 0 16px #ef9e40;
            }
        }

        &--secondary {
            border: 1px solid #ffffff;
            border-radius: 10px;
            transition: border-color 0.25s ease-in-out, color 0.25s ease-in-out;

            &:hover {
                border-color: var(--l-color-jafa);
                color: var(--l-color-jafa);
            }
        }
    }

    .description {
        line-height: 28px;
        font-size: var(--l-font-size-l-plus);
    }

    .section-title {
        font-weight: 700;
        font-size: var(--l-font-size-xxl);
        line-height: 56px;
        text-align: center;
        margin-bottom: 50px;

        @include mobile {
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 40px;
        }
    }

    .info-banner {
        max-width: 730px;
        padding: 40px;
        background: rgba(34, 20, 12, 0.8);
        border-radius: 20px;
        margin: 0 auto;
        position: relative;
        overflow: hidden;

        @include mobile {
            padding: 20px;
        }

        &:after,
        &:before {
            content: "";
            width: 250px;
            height: 250px;
            background: rgba(239, 158, 64, 0.4);
            filter: blur(149px);
            position: absolute;
            border-radius: 100%;
        }

        &:after {
            top: -23%;
            left: -20%;
        }

        &::before {
            right: -20%;
            bottom: -23%;
        }

        &__desc {
            span {
                font-weight: 500;
            }

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }

    .select {
        position: relative;
        width: 42px;
    }

    .select__toggle {
        display: flex;
        cursor: pointer;
        align-items: center;
        width: 100%;
        user-select: none;
        justify-content: space-between;
        color: inherit;
    }

    .select__toggle::after {
        content: "";
        width: 11px;
        height: 6px;
        flex-shrink: 0;
        margin-left: 5px;
        background-size: contain;
        background-image: url("/assets/images/landing/arrow-lang.svg");
        background-repeat: no-repeat;
        transition: transform 0.25s ease-in-out;
    }

    .select__toggle:focus {
        outline: none;
    }

    .select_show .select__toggle::after {
        transform: rotate(180deg);
    }

    .select__dropdown {
        display: none;
        position: absolute;
        top: 30px;
        left: -10px;
        right: 0;
        overflow-y: auto;
        z-index: 2;
        background-color: rgba(14, 14, 14, 0.9);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 0 20px rgba(1, 1, 1, 0.28);
        backdrop-filter: blur(6px);
        border-radius: 5px;
        width: 58px;
        padding: 5px 0;
    }

    .select_show .select__dropdown {
        display: block;
    }

    .select_show .select__backdrop {
        display: block;
    }

    .select__options {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .select__option {
        padding: 4px 17px;
        font-weight: 400;
        font-size: 18px;
        line-height: 18px;
    }

    .select__option:hover {
        background-color: rgba(239, 158, 64, 0.75);
        cursor: pointer;
        transition: 0.2s background-color ease-in-out;
    }

    .ellipse {
        &-a {
            position: relative;

            &:after {
                border-radius: 100%;
                content: "";
                width: 250px;
                height: 250px;
                background: rgba(239, 158, 64, 0.4);
                filter: blur(149px);
                position: absolute;
            }
        }

        &-b {
            position: relative;

            &:before {
                border-radius: 100%;
                content: "";
                width: 250px;
                height: 250px;
                background: rgba(239, 158, 64, 0.4);
                filter: blur(149px);
                position: absolute;
            }
        }
    }
}
</style>
