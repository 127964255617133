<template>
    <header class="LHeader" id="header">
        <div class="LHeader__container container">
            <a href="#header" class="LHeader__logo">
                <img :src="logo" alt="logo" />
            </a>
            <div class="LHeader-right" :class="isOpenMenu ? 'show' : ''">
                <nav class="LHeader__menu menu">
                    <ul class="menu__list">
                        <li @click="toggle" class="LHeader-menu__item menu__item">
                            <a href="#earn" class="menu__link">
                                {{ $t("landing.menu.howToStart") }}
                            </a>
                        </li>
                        <li @click="toggle" class="LHeader-menu__item menu__item">
                            <router-link to="/academy" class="menu__link">
                                {{ $t("landing.menu.academy") }}
                            </router-link>
                        </li>
                        <li @click="toggle" class="LHeader-menu__item menu__item">
                            <router-link to="/academy/2" class="menu__link">
                                {{ $t("landing.menu.news") }}
                            </router-link>
                        </li>
                    </ul>
                </nav>
                <div class="LHeader-lang select" id="select-1">
                    <button
                        type="button"
                        class="LHeader-lang__button select__toggle btn-reset"
                        name="lang"
                        value=""
                        data-select="toggle"
                        data-index="1"
                    >
                        {{ $i18n.locale.toUpperCase() }}
                    </button>
                    <div class="select__dropdown">
                        <ul class="select__options">
                            <li
                                @click="switchLocale(lang)"
                                v-for="(lang, i) in langs"
                                :key="`Lang${i}`"
                                :value="lang"
                                class="select__option"
                                data-select="option"
                                :data-value="lang"
                                :data-index="i"
                            >
                                {{ lang.toUpperCase() }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="LHeader-navs">
                    <a href="" @click="(e) => handleAuthClick('login', e)" class="LHeader__connect btn-reset">
                        {{ $t("landing.menu.login") }}
                    </a>
                    <a href="" @click="(e) => handleAuthClick('signup', e)" class="LHeader__connect btn-reset">
                        {{ $t("landing.menu.signup") }}
                    </a>
                    <div class="LHeader-langs">
                        <button
                            @click="switchLocale(lang)"
                            v-for="(lang, i) in langs"
                            :key="`Lang${i}`"
                            :value="lang"
                            type="button"
                            class="LHeader-langs__button LHeader-langs__button--disabled btn-reset"
                        >
                            {{ lang.toUpperCase() }}
                        </button>
                    </div>
                    <!-- <a class="LHeader-connect" href="">ConnectWallet</a> -->
                </div>
            </div>
            <button @click="toggle" type="button" class="LHeader__burger btn-reset" :class="isOpenMenu ? 'open' : ''">
                <span class="bar-1"> </span>
                <span class="bar-2"> </span>
                <span class="bar-3"> </span>
            </button>
        </div>

        <ConnectModal />
    </header>
</template>

<script>
import { mapMutations, mapGetters, mapActions } from "vuex"
import { getLocalStorageElement } from "@/helpers/localstorage"
import ConnectModal from "@/components/Layout/ConnectModal.vue"
import { LSTORAGE, LANGS } from "@/config/constants"
import { nullEmptyHash } from "@/helpers/matrix"

export default {
    name: "LHeader",
    data() {
        return {
            langs: LANGS,
            isOpenMenu: false,
            connectionType: null
        }
    },
    components: {
        ConnectModal
    },
    computed: {
        logo() {
            return require("@/assets/images/landing/logo.svg")
        },
        isUserConnected() {
            return this.isConnected || getLocalStorageElement(LSTORAGE.connected)
        },
        ...mapGetters("crypto", ["isConnected", "getParent", "getConnectionWallet"])
    },
    methods: {
        handleAuthClick(type, e) {
            e.preventDefault()
            this.connectionType = type
            this.setConnectionModal({ visible: true, type: "select" })
        },
        async processAuth() {
            const [connectErr, connectRes] = await this.checkConnect()
            if (connectErr) this.$swal(connectErr)

            const hasNoParent = nullEmptyHash(this.getParent) === null

            if (this.connectionType === "signup") {
                // window.location.href = hasNoParent ? "/signup" : "/dashboard"
                await this.$router.push(hasNoParent ? "/signup" : "/dashboard")
            } else if (this.connectionType === "login") {
                if (!hasNoParent) {
                    // window.location.href = "/dashboard"
                    await this.$router.push("/dashboard")
                } else {
                    this.$swal({
                        title: this.$t("connectModal.swal.title"),
                        showCancelButton: true,
                        confirmButtonColor: "#38c948",
                        cancelButtonColor: "#828282",
                        confirmButtonText: this.$t("connectModal.swal.confirmButtonText"),
                        cancelButtonText: this.$t("connectModal.swal.cancelButtonText")
                    }).then(async (result) => {
                        if (result.isConfirmed) {
                            // window.location.href = "/signup"
                            await this.$router.push("/signup")
                        }
                    })
                }
            }
        },
        toggle() {
            this.isOpenMenu = !this.isOpenMenu
            this.isOpenMenu ? document.body.classList.add("hidden") : document.body.classList.remove("hidden")
        },
        switchLocale(locale) {
            if (this.$i18n.locale !== locale) {
                localStorage.setItem("lang", locale)
                this.$i18n.locale = locale
                this.$moment.locale(locale)
            }
        },
        ...mapMutations("ui", ["setConnectionModal"]),
        ...mapActions("crypto", ["checkConnect"])
    },
    watch: {
        getConnectionWallet(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.processAuth()
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import "~@/assets/scss/mixins";

.menu {
    &__list {
        @include flex-v-center;

        @include mobile {
            flex-direction: column;
            text-align: center;
            width: 100%;
        }
    }

    &__item {
        &:not(:last-child) {
            margin-right: 30px;
            @include mobile {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }
    }

    &__link {
        font-weight: 500;
        font-size: var(--l-font-size-m);
        line-height: 130%;
        transition: color 0.25s ease-in-out;

        &:hover {
            color: var(--l-color-jafa);
        }
    }
}

.LHeader {
    padding-top: 14px;
    position: relative;
    z-index: 10;
    @include mobile {
        padding-top: 28px;
    }
    &-navs {
        flex-shrink: 0;
    }
    &-connect {
        font-weight: 600;
        font-size: 18px;
        line-height: 21px;
        background: linear-gradient(180deg, #eeb445 0%, #ef9e40 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
    }
    &__container {
        @include flex-v-center;
        justify-content: space-between;
        @include mobile {
            justify-content: flex-end;
        }
    }

    &-right {
        display: flex;
        align-items: center;

        @include mobile {
            position: absolute;
            top: 0;
            right: 0;
            height: 100vh;
            width: 100%;
            background-color: var(--l-color-black);
            z-index: 4;
            flex-direction: column;
            padding: 90px 40px 20% 40px;
            justify-content: space-between;
            opacity: 0;
            transform: translateX(100%);
            transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
            &.show {
                opacity: 1;
                transform: translate(0);
            }
        }
    }

    &__logo {
        margin-right: 235px;
        flex-shrink: 0;
        width: 54px;
        height: 40px;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
        @include desktop {
            margin-right: 170px;
        }

        @include tablet {
            margin-right: 70px;
        }
        @include mobile {
            position: relative;
            z-index: 5;
            display: none;
        }
    }

    &__menu {
        margin-right: 100px;

        @include desktop {
            margin-right: 150px;
        }

        @include tablet {
            margin-right: 50px;
        }

        @include mobile {
            margin-right: 0;
            width: 100%;
        }
    }

    &__burger {
        display: none;

        @include mobile {
            display: grid;
            grid-template-columns: 1fr;
            gap: 6px;
            width: 24px;
            height: 24px;
            position: relative;
            z-index: 5;

            .bar-1,
            .bar-2,
            .bar-3 {
                width: 100%;
                height: 1px;
                border-radius: 8px;
                background-color: var(--l-color-white);
                transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
            }

            &.open {
                .bar-1 {
                    transform: translate(0px, 10px) rotate(45deg);
                }

                .bar-2 {
                    opacity: 0;
                }

                .bar-3 {
                    transform: translate(0px, -9px) rotate(-45deg);
                }
            }
        }
    }

    &-lang {
        margin-right: 52px;

        @include mobile {
            display: none;
        }
    }

    &-lang__button {
        font-weight: 400;
        font-size: var(--l-font-size-m);
        line-height: 27px;
    }

    &__connect {
        font-weight: 600;
        font-size: var(--l-font-size-m);
        line-height: 21px;
        background: linear-gradient(180deg, #eeb445 0%, #ef9e40 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        @include mobile {
            margin-bottom: 20px;
        }
        margin-right: 16px;
        &:last-of-type {
            margin-right: 0;
        }
    }

    &-langs {
        display: none;

        @include mobile {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 24px;
            &__button {
                font-weight: 500;
                font-size: 18px;
                line-height: 130%;
                color: var(--l-color-white);
                &:not(:last-child) {
                    margin-right: 20px;
                }
                &--disabled {
                    color: #c5c5c5;
                }
            }
        }
    }
}
</style>
