<template>
  <div id="app">
    <preloader />
    <app-layout>
      <router-view />
    </app-layout>
  </div>
</template>

<script>
import AppLayout from "@/layouts/AppLayout.vue"
import Preloader from "@/components/UI/Preloader.vue"
export default {
  name: "App",
  components: { Preloader, AppLayout }
}
</script>
