import { $api } from "@/plugins/Axios"

export default {
    state: {
        categories: [],
        category: {},
    },
    getters: {
        allCategories(state) {
            return state.categories
        },
        getCategory(state) {
            return state.category
        },
    },
    mutations: {
        updateCategories(state, categories) {
            state.categories = categories
        },
        updateCategory(state, category) {
            state.category = category
        },
    },
    actions: {
        async fetchCategories(ctx) {
            $api.get("/categories").then((response) => {
                const categories = response.data.data
                ctx.commit("updateCategories", categories)
            })
        },

        async fetchCategory(ctx, id = 0) {
            let get = ""
            if (id !== 0) {
                get += `/${id}`
            }

            $api.get(`/categories${get}`).then((response) => {
                const category = response.data.data
                ctx.commit("updateCategory", category)
            })
        },
    },
}
