import { DICT, LSTORAGE } from "@/config/constants"
import { getLocalStorageElement } from "@/helpers/localstorage"
import { Log, SentryLog } from "@/helpers/dev"
import WalletConnectProvider from "@walletconnect/web3-provider"
import cryptoStore from "@/store/modules/crypto"
import { vm } from "@/main.js"

export const isEthereumWindow = () => {
    try {
        const providerName = getLocalStorageElement(LSTORAGE.wallet)

        if (providerName === "walletconnect") {
            return [null, "walletconnect"]
        }

        const { ethereum } = window
        let condition = ethereum
        let errMessage = `${vm.$t("helpers.crypto.installProvider")}`

        if (providerName === "metamask") {
            condition = ethereum && ethereum.isMetaMask
            errMessage = `${vm.$t("helpers.crypto.installMetaMask")}`
        } else if (providerName === "trust") {
            condition = ethereum && ethereum.isTrust
            errMessage = `${vm.$t("helpers.crypto.installTrustWallet")}`
        }

        if (!condition) {
            throw new Error(errMessage)
        }
        return [null, name]
    } catch (error) {
        return [error, null]
    }
}

export const createProvider = () => {
    let provider = window.ethereum

    const providerName = getLocalStorageElement(LSTORAGE.wallet)
    Log("provider detection", providerName)

    if (providerName === "walletconnect") {
        provider = new WalletConnectProvider({
            // infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
            rpc: {
                [DICT.CHAIN_ID]: DICT.PROVIDER
            },
            chainId: DICT.CHAIN_ID,
            network: "polygon",
            qrcode: true
        })
    }

    return provider
}

export const switchNetwork = async () => {
    const { provider, web3 } = cryptoStore.state
    const providerName = getLocalStorageElement(LSTORAGE.wallet)

    let isMetamaskLike = providerName === "metamask"
    if (providerName === "walletconnect") {
        try {
            Log("PeerMeta", provider.connector._peerMeta, provider.connector._peerMeta.name)
            isMetamaskLike = ["MetaMask"].includes(provider.connector._peerMeta.name)
        } catch (e) {
            isMetamaskLike = false
        }
    }

    try {
        if (isMetamaskLike) {
            try {
                // проверить правильность сети и предложить переключиться если добавлена
                const curChain = await web3.eth.getChainId()
                console.log(curChain)
                if (curChain !== DICT.CHAIN_ID) {
                    await provider.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: web3.utils.toHex(DICT.CHAIN_ID) }]
                    })
                }
            } catch (error) {
                if (error.code === 4902) {
                    // предложить добавить нужную сеть если нет
                    try {
                        await provider.request({
                            method: "wallet_addEthereumChain",
                            params: [
                                {
                                    chainId: web3.utils.toHex(DICT.CHAIN_ID),
                                    chainName: DICT.CHAIN_NAME[DICT.IS_TESTNET ? 1 : 0],
                                    nativeCurrency: {
                                        name: DICT.NATIVE_CURRENCY_SYMBOL,
                                        symbol: DICT.NATIVE_CURRENCY_SYMBOL,
                                        decimals: 18
                                    },
                                    blockExplorerUrls: DICT.EXPLORER_URLS[DICT.IS_TESTNET ? 1 : 0],
                                    rpcUrls: [DICT.PROVIDER]
                                }
                            ]
                        })

                        return [null, true]
                    } catch (addError) {
                        throw new Error(
                            `${vm.$t("helpers.crypto.switchNetwork.lineOne")}
                         ${DICT.CHAIN_ID}
                         ${vm.$t("helpers.crypto.switchNetwork.lineTwo")}`
                        )
                    }
                }

                Log("provider.request error", error)
                throw new Error(`${vm.$t("helpers.crypto.switchNetwork.to")} ${DICT.CHAIN_ID}`)
            }
        } else {
            const curChain = await web3.eth.getChainId()
            Log("curChain", curChain)
            if (curChain !== DICT.CHAIN_ID) {
                // vm.$toast.error(`Invalid chain, should be: ${DICT.CHAIN_ID}`)
                try {
                    await provider.request({
                        method: "wallet_switchEthereumChain",
                        params: [{ chainId: web3.utils.toHex(DICT.CHAIN_ID) }]
                    })

                    return [null, "redispatch"]
                } catch {
                    // DEBUG
                    // vm.$toast.error(`Invalid network: ${curChain}`)
                    window.location.reload()
                    throw new Error(`${vm.$t("helpers.crypto.switchNetwork.to")} ${DICT.CHAIN_ID}`)
                }
            }
        }

        return [null, true]
    } catch (error) {
        return [error, null]
    }
}

export const getLatestBlock = async (name = "latest") => {
    const { web3 } = cryptoStore.state

    try {
        const block = await web3.eth.getBlock(name)

        return [null, block]
    } catch (error) {
        // some rpc throw error on pending syntax
        if (name === "pending") {
            const block = await web3.eth.getBlock("latest")
            if (block) return [null, block]
        }
        SentryLog(error, "getLatestBlock")
        return [new Error(`${vm.$t("helpers.crypto.lastBlockError")}`), null]
    }
}

export const tokenFormatFromWei = (wei, decimals = "ether") => {
    const { web3 } = cryptoStore.state

    const ethers = web3.utils.fromWei(wei, decimals)
    return Number(ethers)
}

export const getEthAccount = async () => {
    const { provider, web3 } = cryptoStore.state
    // const providerName = getLocalStorageElement(LSTORAGE.wallet)

    try {
        let accounts = []
        // if (providerName === "metamask") {
        try {
            accounts = await provider.request({ method: "eth_requestAccounts" })
        } catch {
            accounts = await web3.eth.getAccounts()
        }

        if (!accounts[0]) {
            throw new Error()
        }

        Log("my account", accounts[0])
        return [null, accounts[0]]
    } catch (error) {
        Log("connect error?", error)
        let errorMsg = `${vm.$t("errors.connectWalletError")}`

        switch (error.code) {
            case -32002:
                errorMsg = `${vm.$t("helpers.crypto.checkPlugin")}`
                break

            default:
                break
        }

        return [new Error(errorMsg), null]
    }
}

export const getRPCErrorJSON = (err) => {
    const open = err.indexOf("{")
    const close = err.lastIndexOf("}")
    const j_s = err.substring(open, close + 1)

    return JSON.parse(j_s)
}

export const increaseGas = (gas, key = "ESTIMATED_GAS_INCREASE") => {
    return Math.round(gas * DICT[key])
}

// error codes
// https://eips.ethereum.org/EIPS/eip-1193#provider-errors
// https://eips.ethereum.org/EIPS/eip-1474#error-codes

export const getRevertReason = (err, defaultMessage = `${vm.$t("helpers.crypto.error")}`) => {
    try {
        if (Number(err.code)) {
            // regular metamask error
            switch (err.code) {
                case 4001:
                    return new Error(`${vm.$t("helpers.crypto.cancelled")}`)

                default:
                    return new Error(err.message)
            }
        } else if (err.message.split("(argument").length > 1) {
            // RCP error with argument error ??? (i.e registration)
            return new Error(err.message.split("(argument")[0])
        } else if (err.message.indexOf("{") === -1) {
            // regular api or custom error
            return err
        } else {
            // likelly RPC Error wioth require syntax
            const { message } = getRPCErrorJSON(err.message)

            return new Error(message)
        }

        // const reason = j.data[Object.keys(j.data)[0]].reason
    } catch (e) {
        return new Error(defaultMessage)
    }
}
