<template>
    <div class="video-wrap">
        <video class="video" autoplay muted loop playsinline preload="auto" id="myVideo">
            <source :src="mp4" type="video/mp4" />
        </video>
    </div>
</template>
<script>
export default {
    props: {
        mp4: String
    }
}
</script>

<style>
.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>
