import { Warn } from "@/helpers/dev"

export const getLocalStorageElement = (name) => {
    let el = localStorage.getItem(name)
    if (el) return JSON.parse(el)

    return null
}

export const setLocalStorageElement = (name, value) => {
    try {
        localStorage.setItem(name, JSON.stringify(value))
    } catch (err) {
        Warn(`LocalStorage error on ${name}`, value)
    }
}
